/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Menu, Modal } from "antd";
import { FiDownload, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FileIcon, defaultStyles } from "react-file-icon";

import http from "../../services/http";
import { showSuccess } from "../../services/notification";
import { hasAction, actions } from "../../services/security";

import "./ActaApertura.scss";

const ActaApertura = (props) => {
  const { licitacion, readonly, reload, toggleReload } = props;

  const fileRef = useRef(null);

  const CREAR = hasAction(actions.LICITACIONES_EDITAR);
  const EDITAR = hasAction(actions.LICITACIONES_EDITAR);
  const GUARDAR = licitacion > 0 ? CREAR : EDITAR;

  const [files, setFiles] = useState([]);

  const onAdd = () => {
    fileRef.current.click();
  };

  const onUpload = async (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      await upload(files[i]);
    }

    e.target.value = "";
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const upload = async (file) => {
    const base64 = await toBase64(file);

    const data = {
      nombre: file.name,
      tamanio: file.size.toString(),
      contenido: base64,
      licitacionId: licitacion,
    };

    const response = await http.post(
      `licitaciones/${licitacion}/documentacion/apertura`,
      data
    );
    if (response && response.data.code === 0) {
      toggleReload();
    }
  };

  const onDownload = (file) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${file.id}`,
      "_blank"
    );
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta acta de apertura?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const response = await http.delete(
          `licitaciones/${licitacion}/documentacion/apertura/${item.id}`
        );
        if (response && response.data.code === 0) {
          showSuccess("El acta de apertura fue eliminado exitosamente.");
          toggleReload();
        }
      },
    });
  };

  const menu = (file) => {
    return (
      <Menu className="table-actions-menu">
        <Menu.Item key="1" onClick={() => onDownload(file)}>
          <FiDownload />
          Descargar
        </Menu.Item>
        {GUARDAR && !readonly && (
          <Menu.Item key="2" onClick={() => onDelete(file)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getFiles() {
      const response = await http.get(
        `licitaciones/${licitacion}/documentacion/apertura?licitacion=${licitacion}`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setFiles(data.files);
      }
    }

    getFiles();
  }, [licitacion, reload]);

  return (
    <div className="acta-apertura card">
      <div className="section-header">
        <div className="section-information">
          <div className="section-title">Acta de Apertura</div>
          <div className="section-subtitle">
            Cargá el acta con los resultados de la contratación
          </div>
        </div>
      </div>
      <table className="table-small">
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th style={{ textAlign: "center" }}>Fecha</th>
            <th style={{ textAlign: "center" }}>Hora</th>
            <th style={{ textAlign: "center" }}>Tamaño</th>
          </tr>
        </thead>
        <tbody>
          {files.length === 0 && (
            <tr>
              <td className="table-empty" colSpan="6">
                Sin resultados
              </td>
            </tr>
          )}
          {files.map((x) => (
            <tr key={x.id}>
              <td className="table-actions">
                <Dropdown
                  key={x.id}
                  overlay={() => menu(x)}
                  trigger={["click"]}
                  placement="bottomLeft"
                  arrow
                >
                  <div>
                    <FiMoreVertical />
                  </div>
                </Dropdown>
              </td>
              <td className="td-icon">
                <div className="icon">
                  <FileIcon
                    extension={x.extension}
                    {...defaultStyles[x.extension]}
                  />
                </div>
                {x.nombre}
              </td>
              <td style={{ textAlign: "center" }}>{x.fecha}</td>
              <td style={{ textAlign: "center" }}>{x.hora}</td>
              <td style={{ textAlign: "center" }}>{x.tamanioTexto}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {GUARDAR && !readonly && files.length === 0 && (
        <div className="form-actions">
          <input type="file" accept=".pdf" ref={fileRef} onChange={onUpload} />
          <Button type="primary" onClick={onAdd}>
            Subir
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActaApertura;
