/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";

import http from "./../../../services/http";

import "./FeriadosForm.scss";

const FeriadosForm = ({ item, onClose }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!item) {
      return;
    }

    form.setFieldsValue(item);
  }, [item]);

  const onSave = async (values) => {
    item.descripcion = values.descripcion;

    const response =
      item.id === 0
        ? await http.post("feriados", item)
        : await http.put(`feriados/${item.id}`, item);

    if (response && response.data.code === 0) {
      onCancel();
    }
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      className="feriados-form"
      visible={item}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}
      keyboard={false}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form">
          <div className="title">
            {item && item.id === 0 ? "Agregar" : "Editar"} Feriado
          </div>

          <Form.Item label="Fecha" name="fecha">
            <Input />
          </Form.Item>

          <Form.Item label="Descripción" name="descripcion">
            <Input />
          </Form.Item>

          <div className="form-actions">
            <Button type="secondary" onClick={onCancel}>
              Volver
            </Button>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default FeriadosForm;
