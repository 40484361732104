/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { showWarning, showError } from "./notification";
import { getJwt } from "./security";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(null, (error) => {
  showError("¡Ups! Parece que hay problemas de conexión con el servidor.");
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  if (response.data.code === -1) {
    showError(
      "¡Ups! Parece que ocurrió un error. Por favor intenta nuevamente."
    );
  } else if (response.data.code === 1) {
    showWarning(response.data.message);
  } else if (response.data.code === 2) {
    if (response.data.message !== undefined) {
      showWarning(response.data.message);
    }
  }

  return response;
});

const httpGet = async (url) => {
  const config = { headers: { "x-auth-token": getJwt() } };
  return await axios.get(url, config).catch(() => {});
};

const httpGetFile = async (url) => {
  const config = {
    headers: { "x-auth-token": getJwt() },
    responseType: "blob",
  };
  return await axios.get(url, config).catch(() => {});
};

const httpPost = async (url, data) => {
  const config = { headers: { "x-auth-token": getJwt() } };
  return await axios.post(url, data, config).catch(() => {});
};

const httpPostFile = async (url, data, setProgress) => {
  const config = {
    headers: {
      "x-auth-token": getJwt(),
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "json",
    onUploadProgress: (data) => {
      //Set the progress value to show the progress bar
      setProgress(Math.round((100 * data.loaded) / data.total));
    },
  };
  return await axios.post(url, data, config).catch((error) => {
    console.log(error);
    setProgress(null);
  });
};

const httpPatch = async (url, data) => {
  const config = { headers: { "x-auth-token": getJwt() } };
  return await axios.patch(url, data, config).catch(() => {});
};

const httpPut = async (url, data) => {
  const config = { headers: { "x-auth-token": getJwt() } };
  return await axios.put(url, data, config).catch(() => {});
};

const httpDelete = async (url) => {
  const config = { headers: { "x-auth-token": getJwt() } };
  return await axios.delete(url, config).catch(() => {});
};

export default {
  get: httpGet,
  getFile: httpGetFile,
  post: httpPost,
  postFile: httpPostFile,
  patch: httpPatch,
  put: httpPut,
  delete: httpDelete,
};
