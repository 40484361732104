/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Modal, Tag, Tooltip } from "antd";
import { FiChevronDown, FiChevronUp, FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SlideDown } from "react-slidedown";

import http from "../../../services/http";
import { showSuccess } from "../../../services/notification";
import { hasAction, actions } from "../../../services/security";

import "./Documentos.scss";
import DocumentosForm from "./DocumentosForm";

const Documentos = ({ id }) => {
  const CREAR = hasAction(actions.TRAMITES_EDITAR);
  const EDITAR = hasAction(actions.TRAMITES_EDITAR);
  const GUARDAR = id > 0 ? CREAR : EDITAR;

  const [list, setList] = useState([]);
  const [reload, setReload] = useState(false);
  const [closed, setClosed] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    async function getData() {
      const response = await http.get(`tramites/${id}/documentos`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setList(data.list);
      }
    }

    getData();
  }, [id, reload]);

  const renderObligatoriedad = (documento) => {
    let color = "green";
    let text = "Opcional";

    if (documento.obligatorio) {
      color = "gold";
      text = "Obligatorio";
    }

    return (
      <div onClick={() => onClickObligatoriedad(documento)}>
        <Tag className="obligatorio" color={color}>
          {text}
        </Tag>
      </div>
    );
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const onClickObligatoriedad = async (item) => {
    const response = await http.patch(`tramites/${id}/documentos/${item.id}`);
    if (response && response.data.code === 0) {
      setReload(!reload);
    }
  };

  const onClickDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este documento?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const response = await http.delete(
          `tramites/${id}/documentos/${item.id}`
        );
        if (response && response.data.code === 0) {
          showSuccess("El trámite fue eliminado exitosamente.");
          setReload(!reload);
        }
      },
    });
  };

  return (
    <div className="documentos card">
      <div className="section-header" onClick={() => setClosed(!closed)}>
        <div className="section-information">
          <div className="section-title">Documentos</div>
          <div className="section-subtitle">
            Documentos asociados al trámite.
          </div>
        </div>
        <div className="section-toggle">
          {closed ? <FiChevronDown /> : <FiChevronUp />}
        </div>
      </div>

      <SlideDown className={"my-dropdown-slidedown"} closed={closed}>
        <table className="table-small">
          <thead>
            <tr>
              <th>Nombre</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="3">
                  Sin resultados
                </td>
              </tr>
            )}
            {list.map((documento, index) => (
              <tr key={index}>
                <td>{documento.nombre}</td>
                <td>{renderObligatoriedad(documento)}</td>
                <td>
                  <Tooltip placement="top" title="Eliminar">
                    <div
                      className="delete"
                      onClick={() => onClickDelete(documento)}
                    >
                      <FiTrash2 />
                    </div>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <DocumentosForm
          id={id}
          visible={visible}
          onSave={() => setReload(!reload)}
          onClose={() => setVisible(false)}
        />

        {GUARDAR && (
          <div className="form-actions">
            <Button type="primary" onClick={onClickAdd}>
              Agregar
            </Button>
          </div>
        )}
      </SlideDown>
    </div>
  );
};

export default Documentos;
