/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Pagination, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { Context } from "./Context";
import http from "../services/http";

import empty from "./../assets/images/empty.svg";

import "./Table.scss";
import { FiMoreVertical } from "react-icons/fi";

const Table = (props) => {
  const { url, renderMenu } = props;

  const history = useHistory();
  const [state, setState] = useContext(Context);

  useEffect(() => {
    async function getList() {
      if (!state.filtersLoaded) {
        return;
      }

      setState({ ...state, loading: true });

      const response = await http.post(url, {
        page: state.page,
        size: state.size,
        ...state.filters,
      });

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setState({
          ...state,
          list: data.list,
          total: data.total,
          loading: false,
        });
      } else {
        setState({ ...state, loading: false });
      }

      localStorage.setItem(
        history.location.pathname,
        JSON.stringify({
          filters: state.filters,
          page: state.page,
          size: state.size,
        })
      );
    }

    getList();
  }, [state.page, state.size, state.filtersLoaded, state.reload]);

  const onChangeSize = (value) => {
    setState({ ...state, size: value });
  };

  const onChangePage = (value) => {
    setState({ ...state, page: value });
  };

  return (
    <div className="table">
      {state.loading && (
        <div className="loading">
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 40, color: "#04bebe" }}
                spin
              />
            }
          />
          Cargando...
        </div>
      )}

      {!state.loading && state.total === 0 && (
        <div className="empty">
          <img src={empty} alt="empty" />
          Sin resultados
        </div>
      )}

      {!state.loading && state.total > 0 && (
        <React.Fragment>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  {renderMenu && <th></th>}
                  {state.columns.map((column, index) => (
                    <th key={index} style={column.style ?? {}}>
                      {column.title ?? ""}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {state.list.map((item, index) => (
                  <tr key={index} className={item.backgroundColor}>
                    {renderMenu && (
                      <td className="table-actions">
                        <Dropdown
                          overlay={() => renderMenu(item)}
                          trigger={["click"]}
                          placement="bottomLeft"
                          arrow
                        >
                          <div>
                            <FiMoreVertical />
                          </div>
                        </Dropdown>
                      </td>
                    )}
                    {state.columns.map((column, indexCol) => (
                      <td key={indexCol} style={column.style ?? {}}>
                        {column.render(item)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="footer">
            <div className="results">
              <div>Mostrando</div>
              <Select
                className="pagesize"
                value={state.size}
                onChange={onChangeSize}
              >
                <Select.Option value="10">10</Select.Option>
                <Select.Option value="20">20</Select.Option>
                <Select.Option value="50">50</Select.Option>
                <Select.Option value="100">100</Select.Option>
              </Select>
              <div> de {state.total} resultados</div>
            </div>
            <Pagination
              defaultPageSize={state.size}
              current={state.page}
              total={state.total}
              showSizeChanger={false}
              onChange={onChangePage}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Table;
