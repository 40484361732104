/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Calendar } from "antd";
import { FiCalendar } from "react-icons/fi";
import * as moment from "moment";

import http from "../../../services/http";
import { actions, hasAction } from "../../../services/security";

import Header from "../../../components/Header";
import FeriadosForm from "./FeriadosForm";

const Feriados = () => {
  const [list, setList] = useState([]);
  const [current, setCurrent] = useState();
  const [reload, setReload] = useState(false);

  const [month, setMonth] = useState(moment().format("M"));
  const [year, setYear] = useState(moment().format("Y"));

  const VER = hasAction(actions.FERIADOS_VER);
  const CREAR = hasAction(actions.FERIADOS_CREAR);
  const EDITAR = hasAction(actions.FERIADOS_EDITAR);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Feriados", url: "/configuracion/feriados" },
  ];

  useEffect(() => {
    async function getForm() {
      const response = await http.get(
        `feriados/list?anio=${year}&mes=${month}`
      );

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setList(data.list);
      }
    }

    getForm();
  }, [month, year, reload]);

  const onChangeMonth = (value) => {
    setMonth(value.format("M"));
    setYear(value.format("Y"));
  };

  const renderDay = (value) => {
    const isToday = moment().startOf("day").isSame(value.startOf("day"), "day");
    const feriado = list.find((x) => x.fecha === value.format("DD/MM/YYYY"));
    const classes = `calendar-day 
      ${isToday ? "calendar-today" : ""} 
      ${feriado ? "calendar-background" : ""}`;

    return (
      <div className={classes} onClick={() => onClickDay(value)}>
        <div className="calendar-date">{value.format("D")}</div>
        <div className="calendar-content">
          <div className="calendar-event">{feriado?.descripcion}</div>
        </div>
      </div>
    );
  };

  const onClickDay = async (value) => {
    const response = await http.get(
      `feriados?fecha=${value.format("DD/MM/YYYY")}`
    );
    if (response && response.data.code === 0) {
      const data = response.data.data;
      data.fecha = value.format("DD/MM/YYYY");

      if (data.id === 0 && CREAR) {
        setCurrent(data);
      }

      if (data.id !== 0 && EDITAR) {
        setCurrent(data);
      }
    }
  };

  const onCloseForm = () => {
    setCurrent(undefined);
    setReload(!reload);
  };

  if (!VER) {
    return <Redirect to="" />;
  }

  return (
    <div className="feriados card card-page">
      <Header title="Feriados" breadcrumb={breadcrumb} icon={<FiCalendar />} />
      <Calendar
        className="month"
        dateFullCellRender={renderDay}
        onPanelChange={onChangeMonth}
      />
      <FeriadosForm item={current} onClose={onCloseForm} />
    </div>
  );
};

export default Feriados;
