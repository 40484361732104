/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiBell,
  FiDollarSign,
  FiEdit,
  FiPlus,
  FiSearch,
  FiTrash2,
} from "react-icons/fi";

import { ContextProvider } from "../../components/Context";
import { showSuccess } from "../../services/notification";
import http from "../../services/http";
import { actions, hasAction } from "../../services/security";

import Header from "../../components/Header";
import Table from "../../components/Table";
import FiltersButton from "../../components/FiltersButton";
import Filters from "../../components/Filters";

const { Option } = Select;

const Licitaciones = () => {
  const history = useHistory();

  const [state, setState] = useState({
    filters: {
      numero: "",
      expediente: "",
      nombre: "",
      estado: "",
      tipo: "",
      notificacion: "",
    },
    columns: [
      {
        title: "Número de Contratación",
        render: (item) => item.numero,
      },
      {
        title: "Número de Expediente",
        render: (item) => item.expediente,
      },
      {
        title: "Nombre",
        render: (item) => item.nombre,
      },
      {
        title: "Vigencia",
        render: (item) => item.desde,
      },
      {
        title: "Apertura",
        render: (item) => item.aperturaDesde,
      },
      {
        title: "Estado",
        render: (item) => renderEstado(item.estado),
      },
      {
        render: (item) =>
          item.notificacion && (
            <Badge count={1}>
              <FiBell color="#04bebe" size="20px" />
            </Badge>
          ),
      },
      {
        render: (item) =>
          item.editor && (
            <Tooltip
              placement="top"
              title={`Esta contratación esta siendo editada por ${item.editor}`}
            >
              <div className="table-warning">
                <FiEdit />
              </div>
            </Tooltip>
          ),
      },
    ],
    loading: true,
    dataLoaded: true,
    filtersLoaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const VER = hasAction(actions.LICITACIONES_VER);
  const CREAR = hasAction(actions.LICITACIONES_CREAR);
  const EDITAR = hasAction(actions.LICITACIONES_EDITAR);
  const ELIMINAR = hasAction(actions.LICITACIONES_ELIMINAR);

  const breadcrumb = [
    { id: 1, title: "Contrataciones", url: "/contrataciones" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="secondary" icon={<FiPlus />} onClick={onAdd}>
            Crear
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Número de Contratación" name="numero">
          <Input />
        </Form.Item>
        <Form.Item label="Número de Expediente" name="expediente">
          <Input />
        </Form.Item>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select allowClear>
            <Option value="1">Creada</Option>
            <Option value="2">Vigente</Option>
            <Option value="3">Apertura</Option>
            <Option value="4">Post Apertura</Option>
            <Option value="5">Pre Adjudicacion</Option>
            <Option value="6">Adjudicada</Option>
            <Option value="7">Finalizada</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Notificación" name="notificacion">
          <Select allowClear>
            <Option value="1">Si</Option>
            <Option value="0">No</Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const renderMenu = (item) => {
    return (
      <Menu className="table-actions">
        {VER && (
          <Menu.Item key="0" onClick={() => onEdit(item, false)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        {EDITAR && (
          <Menu.Item key="1" onClick={() => onEdit(item, true)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="2" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const renderEstado = (estado) => {
    switch (estado) {
      case 1:
        return <Tag color="gold">Creada</Tag>;

      case 2:
        return <Tag color="lime">Vigente</Tag>;

      case 3:
        return <Tag color="green">Apertura</Tag>;

      case 4:
        return <Tag color="cyan">Post Apertura</Tag>;

      case 5:
        return <Tag color="blue">Pre Adjudicación</Tag>;

      case 6:
        return <Tag color="geekblue">Adjudicada</Tag>;

      case 7:
        return <Tag color="purple">Finalizada</Tag>;

      default:
        return <Tag>-</Tag>;
    }
  };

  const onAdd = () => {
    history.push("/contrataciones/nuevo");
  };

  const onEdit = (item, edit) => {
    history.push(`/contrataciones/${item.id}?edit=${edit}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta contrataciones?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`licitaciones/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La contratacion fue eliminada exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="licitaciones card card-page">
        <Header
          title="Contrataciones"
          breadcrumb={breadcrumb}
          icon={<FiDollarSign />}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="licitaciones/list" renderMenu={renderMenu} />
      </div>
    </ContextProvider>
  );
};

export default Licitaciones;
