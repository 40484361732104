/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Select, Row, Col } from "antd";
import { FiUser } from "react-icons/fi";

import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notification";
import { actions, hasAction } from "./../../../services/security";
import { required } from "./../../../services/validation";

import Header from "./../../../components/Header";

const { Option } = Select;

const UsuariosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [id] = useState(
    match.params.id === "nuevo" ? 0 : parseInt(match.params.id)
  );
  const [title] = useState(id === 0 ? "Crear" : "Editar");
  const [loading, setLoading] = useState(false);

  const [perfiles, setPerfiles] = useState([]);

  const VER = hasAction(actions.USUARIOS_VER);
  const CREAR = hasAction(actions.USUARIOS_CREAR);
  const EDITAR = hasAction(actions.USUARIOS_EDITAR);
  const GUARDAR = (id === 0 && CREAR) || (id > 0 && EDITAR);

  const breadcrumb = [
    { id: 1, title: "Seguridad" },
    { id: 2, title: "Usuarios", url: "/seguridad/usuarios" },
    {
      id: 3,
      title: title,
      url: `/seguridad/usuarios/${match.params.id}`,
    },
  ];

  const onPerfilChange = (value) => {};

  const onSave = async (values) => {
    setLoading(true);

    const rSave =
      id === 0
        ? await http.post("usuarios", values)
        : await http.put(`usuarios/${id}`, values);

    if (rSave && rSave.data.code === 0) {
      history.goBack();
      showSuccess("El usuario fue guardado exitosamente.");
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getForm() {
      const rData = await http.get("usuarios/data");
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setPerfiles(data.perfiles);
      }

      const rForm = await http.get(`usuarios/${id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;

        form.setFieldsValue(data);
      }
    }

    getForm();
  }, []);

  if (!VER) {
    return <Redirect to="" />;
  }

  return (
    <div className="usuarios-form card card-page">
      <Header
        title={`${title} Usuario`}
        breadcrumb={breadcrumb}
        icon={<FiUser />}
      />

      <Form
        form={form}
        layout="vertical"
        className="form-page"
        onFinish={onSave}
      >
        <Form.Item label="Nombre" name="nombre" rules={[required]}>
          <Input maxLength={200} />
        </Form.Item>

        <Row gutter={20}>
          <Col span="12">
            <Form.Item label="Email" name="email" rules={[required]}>
              <Input maxLength={200} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Teléfono" name="telefono">
              <Input maxLength={200} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Perfiles" name="perfiles" rules={[required]}>
          <Select allowClear onChange={onPerfilChange} mode="multiple">
            {perfiles.map((g) => (
              <Option key={g.value} value={g.value}>
                {g.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={history.goBack}>
            Volver
          </Button>
          {GUARDAR && (
            <Button type="primary" htmlType="submit" loading={loading}>
              {!loading && "Guardar"}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default UsuariosForm;
