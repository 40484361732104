/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Menu, Modal, Select, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiUser,
  FiEdit,
  FiPlus,
  FiCheckCircle,
  FiXCircle,
} from "react-icons/fi";

import { ContextProvider } from "./../../../components/Context";
import { showSuccess } from "./../../../services/notification";
import http from "./../../../services/http";
import { actions, hasAction } from "./../../../services/security";

import Header from "./../../../components/Header";
import Table from "./../../../components/Table";
import FiltersButton from "./../../../components/FiltersButton";
import Filters from "./../../../components/Filters";

const { Option } = Select;

const Usuarios = () => {
  const history = useHistory();

  const [state, setState] = useState({
    filters: {
      visible: false,
      nombre: "",
      email: "",
      perfil: null,
    },
    columns: [
      {
        title: "Nombre",
        render: (item) => item.nombre,
      },
      {
        title: "Email",
        render: (item) => item.email,
      },
      {
        title: "Perfil",
        render: (item) => item.perfilTexto,
      },
      {
        title: "Estado",
        render: (item) =>
          item.habilitado ? (
            <Tag color="green">Habilitado</Tag>
          ) : (
            <Tag color="red">Deshabilitado</Tag>
          ),
      },
    ],
    loading: true,
    dataLoaded: false,
    filtersLoaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });
  const [perfiles, setPerfiles] = useState([]);

  const VER = hasAction(actions.USUARIOS_VER);
  const CREAR = hasAction(actions.USUARIOS_CREAR);
  const EDITAR = hasAction(actions.USUARIOS_EDITAR);
  const ELIMINAR = hasAction(actions.USUARIOS_ELIMINAR);

  const breadcrumb = [
    { id: 1, title: "Seguridad" },
    { id: 2, title: "Usuarios", url: "/seguridad/usuarios" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="secondary" icon={<FiPlus />} onClick={onAdd}>
            Crear
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Perfil" name="perfil">
          <Select allowClear>
            {perfiles.map((g) => (
              <Option key={g.value} value={g.value}>
                {g.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const renderMenu = (item) => {
    return (
      <Menu className="table-actions">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            {item.habilitado ? (
              <React.Fragment>
                <FiXCircle />
                Deshabilitar
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FiCheckCircle />
                Habilitar
              </React.Fragment>
            )}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("usuarios/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setPerfiles(data.perfiles);

        console.log("Todo listo");
        setState({ ...state, dataLoaded: true });
      }
    }

    getData();
  }, []);

  const onAdd = () => {
    history.push("/seguridad/usuarios/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/seguridad/usuarios/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: `Confirmar ${
        item.habilitado ? "deshabilitación" : "habilitación"
      }`,
      icon: <ExclamationCircleOutlined />,
      content: `¿Esta seguro que desea ${
        item.habilitado ? "deshabilitar" : "habilitar"
      }  a este usuario?`,
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`usuarios/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess(
            `"El usuario fue ${
              item.habilitado ? "deshabilitado" : "habilitado"
            } exitosamente.`
          );
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="usuarios card card-page">
        <Header
          title="Usuarios"
          breadcrumb={breadcrumb}
          icon={<FiUser />}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="usuarios/list" renderMenu={renderMenu} />
      </div>
    </ContextProvider>
  );
};

export default Usuarios;
