/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Select, Tag, Tooltip } from "antd";
import { FiChevronDown, FiChevronUp, FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SlideDown } from "react-slidedown";

import http from "../../services/http";
import { showSuccess } from "../../services/notification";
import { hasAction, actions } from "../../services/security";

import DocumentosForm from "./DocumentosForm";

import "./Documentos.scss";

const Documentos = (props) => {
  const { licitacion, readonly, reload, toggleReload } = props;

  const CREAR = hasAction(actions.LICITACIONES_CREAR);
  const EDITAR = hasAction(actions.LICITACIONES_EDITAR);
  const GUARDAR = licitacion > 0 ? CREAR : EDITAR;

  const [form] = Form.useForm();

  const [list, setList] = useState([]);
  const [tramites, setTramites] = useState([]);
  const [closed, setClosed] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    async function getData() {
      const response = await http.get(`licitaciones/${licitacion}/documentos`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setList(data.documentos);
        setTramites(data.tramites);
        form.setFieldsValue({
          tramite: data.tramiteId ? data.tramiteId.toString() : undefined,
        });
      }
    }

    getData();
  }, [licitacion, reload]);

  const renderObligatoriedad = (documento) => {
    let color = "green";
    let text = "Opcional";

    if (documento.obligatorio) {
      color = "gold";
      text = "Obligatorio";
    }

    return (
      <div onClick={() => onClickObligatoriedad(documento)}>
        <Tag className="obligatorio" color={color}>
          {text}
        </Tag>
      </div>
    );
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const onChangeTramite = async (value) => {
    const response = await http.patch(
      `licitaciones/${licitacion}/documentos/tramite/${value ?? 0}`
    );

    if (response && response.data.code === 0) {
      toggleReload();
    }
  };

  const onClickObligatoriedad = async (item) => {
    if (readonly) {
      return;
    }

    const response = await http.patch(
      `licitaciones/${licitacion}/documentos/${item.id}`
    );

    if (response && response.data.code === 0) {
      toggleReload();
    }
  };

  const onClickDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este documento?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const response = await http.delete(
          `licitaciones/${licitacion}/documentos/${item.id}`
        );
        if (response && response.data.code === 0) {
          showSuccess("El trámite fue eliminado exitosamente.");
          toggleReload();
        }
      },
    });
  };

  return (
    <div className="documentos card">
      <div className="section-header" onClick={() => setClosed(!closed)}>
        <div className="section-information">
          <div className="section-title">Documentos</div>
          <div className="section-subtitle">
            Documentos que los solicitantes deben cargar para participar.
          </div>
        </div>
        <div className="section-toggle">
          {closed ? <FiChevronDown /> : <FiChevronUp />}
        </div>
      </div>

      <SlideDown className={"my-dropdown-slidedown"} closed={closed}>
        <Form form={form} layout="vertical" className="tramite-form">
          <Form.Item label="Tramite" name="tramite">
            <Select onChange={onChangeTramite} disabled={readonly}>
              {tramites.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>

        <table className="table-small">
          <thead>
            <tr>
              <th>Nombre</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="3">
                  Sin resultados
                </td>
              </tr>
            )}
            {list.map((documento, index) => (
              <tr key={index}>
                <td>{documento.nombre}</td>
                <td>{renderObligatoriedad(documento)}</td>
                <td>
                  {!readonly && (
                    <Tooltip placement="top" title="Eliminar">
                      <div
                        className="delete"
                        onClick={() => onClickDelete(documento)}
                      >
                        <FiTrash2 />
                      </div>
                    </Tooltip>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <DocumentosForm
          licitacion={licitacion}
          visible={visible}
          onSave={() => toggleReload()}
          onClose={() => setVisible(false)}
        />

        {GUARDAR && !readonly && (
          <div className="form-actions">
            <Button type="primary" onClick={onClickAdd}>
              Agregar
            </Button>
          </div>
        )}
      </SlideDown>
    </div>
  );
};

export default Documentos;
