import React from "react";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { FiSidebar, FiPower } from "react-icons/fi";

import { getUser } from "./../services/security";

import icon from "./../assets/images/user.png";
import "./Bar.scss";

const Bar = ({ collapsed, onCollapse }) => {
  const history = useHistory();

  const user = getUser();

  const onLogout = () => {
    localStorage.clear();
    history.push("/login");
  };

  const menuUser = (
    <Menu className="bar-actions">
      <Menu.Item key="1" onClick={onLogout}>
        <FiPower size="1.5em" />
        Cerrar Sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="bar">
      <div className="bar-toggle" onClick={() => onCollapse(!collapsed)}>
        <FiSidebar />
      </div>
      <Dropdown
        overlay={menuUser}
        trigger={["click"]}
        placement="bottomRight"
        arrow
      >
        <div className="user">
          <div className="data">
            <p className="name">{user.nombre}</p>
            <p className="status">Disponible</p>
          </div>
          <img className="icon" src={icon} alt="user" />
        </div>
      </Dropdown>
    </div>
  );
};

export default Bar;
