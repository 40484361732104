import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import Layout from "./views/Layout";
import Login from "./views/Login/Login";

import "./styles/Fonts.scss";
import "./App.css";

import "./styles/Buttons.scss";
import "./styles/Cards.scss";
import "./styles/Calendars.scss";
import "./styles/Container.scss";
import "./styles/DatePickers.scss";
import "./styles/Forms.scss";
import "./styles/Inputs.scss";
import "./styles/Labels.scss";
import "./styles/Modals.scss";
import "./styles/Notifications.scss";
import "./styles/Pagination.scss";
import "./styles/Selects.scss";
import "./styles/Switches.scss";
import "./styles/Tags.scss";

function App() {
  return (
    <React.Fragment>
      <Router>
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route path="/login" component={Login} />
          <Route path="/" component={Layout} />
        </AnimatedSwitch>
      </Router>
    </React.Fragment>
  );
}

export default App;
