import React, { useState } from "react";
import { Modal } from "antd";

import { ContextProvider } from "./../../components/Context";

import LoginForm from "./LoginForm";
import ResetForm from "./ResetForm";
import UpdateForm from "./UpdateForm";

import "./Login.scss";

const Login = () => {
  const [state, setState] = useState({
    email: "",
    current: "login",
  });
  const [visible, setVisible] = useState(true);

  return (
    <Modal
      className="login-form"
      centered
      footer={null}
      width={900}
      visible={visible}
      closable={false}
    >
      <ContextProvider value={[state, setState]}>
        {state.current === "login" && <LoginForm setVisible={setVisible} />}
        {state.current === "reset" && <ResetForm />}
        {state.current === "update" && <UpdateForm />}
      </ContextProvider>
    </Modal>
  );
};

export default Login;
