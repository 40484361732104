/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal, Tooltip } from "antd";
import { FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import SociosForm from "./SociosForm";

import classes from "./Socios.module.scss";

const Socios = (props) => {
  const { list, updateList, readonly } = props;

  const [formVisible, setFormVisible] = useState(false);

  const onClickAdd = () => {
    setFormVisible(true);
  };

  const onClickDelete = async (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este socio?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: () => {
        let nList = [...list];
        nList = nList.filter((x) => x !== item);
        updateList(nList);
      },
    });
  };

  return (
    <div className={classes.socios}>
      <div className={classes.label}>Socios</div>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Cuit</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {list.length === 0 && (
            <tr>
              <td className={classes.empty} colSpan="4">
                Sin resultados
              </td>
            </tr>
          )}

          {list.map((socio, index) => (
            <tr key={index}>
              <td>{socio.nombre}</td>
              <td>{socio.cuit}</td>
              <td>
                <div className={classes.actions}>
                  {!readonly && (
                    <div
                      className={classes.action}
                      onClick={() => onClickDelete(socio)}
                    >
                      <Tooltip title="Eliminar">
                        <FiTrash2 />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}

          {!readonly && (
            <tr>
              <td colSpan={3} className={classes.add} onClick={onClickAdd}>
                Agregar
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <SociosForm
        visible={formVisible}
        readonly={readonly}
        list={list}
        onSave={updateList}
        onClose={() => setFormVisible(false)}
      />
    </div>
  );
};

export default Socios;
