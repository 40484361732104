import { notification } from 'antd';

notification.config({
  placement: 'bottomRight',
  duration: 5,
});

export const showInfo = (message) => {
  notification.info({ message: 'Información', description: message });
};

export const showWarning = (message) => {
  notification.warning({ message: 'Advertencia', description: message });
};

export const showSuccess = (message, duration) => {
  if(duration){
    notification.config({
      placement: 'bottomRight',
      duration: duration,
    });
  }
  else {
    notification.config({
      placement: 'bottomRight',
      duration: 5,
    });
  }
  notification.success({ message: 'Éxito', description: message });
};

export const showError = (message) => {
  notification.error({ message: 'Error', description: message });
};