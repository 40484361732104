import React, { useContext, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";

import { Context } from "./../../components/Context";
import http from "./../../services/http";
import { required } from "./../../services/validation";

import logo from "./../../assets/images/logo-horizontal.png";

const ResetForm = () => {
  const [form] = Form.useForm();
  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    setState({ ...state, current: "login" });
  };

  const onSave = async (values) => {
    setLoading(true);

    const rSave = await http.post("login/reset", values);

    if (rSave && rSave.data.code === 0) {
      setState({ ...state, current: "login" });
    } else {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={0} lg={8}>
        <div className="image">
          <div className="overlay">
            <div>
              <div className="header">Login</div>
              <div className="title">Reestablecer Contraseña</div>
            </div>
            <div className="description">
              Ingresá tu dirección de correo electrónico y te enviaremos nueva
              contraseña temporal.
            </div>
          </div>
        </div>
      </Col>
      <Col xs={24} lg={16}>
        <div className="form">
          <Form form={form} layout="vertical" onFinish={onSave}>
            <img className="logo" src={logo} alt="logo" />

            <Form.Item label="Email" name="email" rules={[required]}>
              <Input autoFocus={true} />
            </Form.Item>

            <div className="form-actions">
              <Form.Item>
                <Button type="secondary" onClick={onCancel}>
                  Volver
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {!loading && "Reestablecer"}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default ResetForm;
