/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Modal, Tooltip } from "antd";
import {
  FiChevronDown,
  FiChevronUp,
  FiDownload,
  FiTrash2,
} from "react-icons/fi";
import { SlideDown } from "react-slidedown";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { downloadFile } from "../../services/files";
import http from "../../services/http";
import { showSuccess } from "./../../services/notification";
import { actions, hasAction } from "./../../services/security";

import PenalidadesModal from "./PenalidadesModal";

import classes from "./Penalidades.module.scss";

const Penalidades = (props) => {
  const { readonly, proveedorId } = props;

  const VER = hasAction(actions.PROVEEDORES_PENALIDADES_VER);
  const CREAR = hasAction(actions.PROVEEDORES_PENALIDADES_CREAR);
  const ELIMINAR = hasAction(actions.PROVEEDORES_PENALIDADES_ELIMINAR);

  const [list, setList] = useState([]);
  const [reload, setReload] = useState(false);
  const [closed, setClosed] = useState(true);

  const [modal, setModal] = useState(false);

  useEffect(() => {
    async function getList() {
      const response = await http.get(`proveedores/${proveedorId}/penalidades`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setList(data.penalidades);
      }
    }

    if (!closed) {
      getList();
    }
  }, [reload, closed]);

  const onClickAdd = () => {
    setModal(true);
  };

  const onClickDownload = async (item) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/proveedores/${proveedorId}/penalidades/${item.id}/archivos`;

    const response = await http.getFile(url);
    downloadFile(`Penalidad${item.id}_Adjuntos.zip`, response.data);
  };

  const onClickDelete = async (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta penalidad?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const url = `proveedores/${proveedorId}/penalidades/${item.id}`;
        const response = await http.delete(url);
        if (response && response.data.code === 0) {
          showSuccess("La penalidad fue eliminada exitosamente.");
          setReload(!reload);
        }
      },
    });
  };

  return (
    <div className={classes.penalidades}>
      <div className={classes.header} onClick={() => setClosed(!closed)}>
        <div className={classes.data}>
          <div className={classes.title}>Penalidades</div>
          <div className={classes.description}>
            Historial de penalidades aplicadas a este proveedor.
          </div>
        </div>
        <div className={classes.toggle}>
          {closed ? <FiChevronDown /> : <FiChevronUp />}
        </div>
      </div>

      <SlideDown closed={closed}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Tipo</th>
              <th>Comentario</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {list.length === 0 && (
              <tr>
                <td className={classes.empty} colSpan="4">
                  Sin resultados
                </td>
              </tr>
            )}

            {list.map((penalidad, index) => (
              <tr key={index}>
                <td>{penalidad.fecha}</td>
                <td>{penalidad.tipoTexto}</td>
                <td>{penalidad.comentario}</td>
                <td>
                  <div className={classes.actions}>
                    {VER && penalidad.archivos.length > 0 && (
                      <div
                        className={classes.action}
                        onClick={() => onClickDownload(penalidad)}
                      >
                        <Tooltip title="Descargar Adjuntos">
                          <FiDownload />
                        </Tooltip>
                      </div>
                    )}
                    {ELIMINAR && !readonly && (
                      <div
                        className={classes.action}
                        onClick={() => onClickDelete(penalidad)}
                      >
                        <Tooltip title="Eliminar">
                          <FiTrash2 />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {CREAR && !readonly && (
          <div className={classes.add}>
            <Button type="primary" onClick={onClickAdd}>
              Agregar
            </Button>
          </div>
        )}
      </SlideDown>

      <PenalidadesModal
        proveedorId={proveedorId}
        visible={modal}
        onSave={() => setReload(!reload)}
        onClose={() => setModal(false)}
      />
    </div>
  );
};

export default Penalidades;
