/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Select } from "antd";

import http from "../../services/http";
import { required } from "../../services/validation";

import "./DocumentosForm.scss";

const DocumentosForm = (props) => {
  const { licitacion, visible, onSave, onClose } = props;

  const [form] = Form.useForm();
  const [documentos, setDocumentos] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        `licitaciones/${licitacion}/documentos/data`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDocumentos(data.documentos);
      }
    }

    if (visible) {
      getData();
    }
  }, [licitacion, visible]);

  const onFinish = async (values) => {
    const response = await http.post(
      `licitaciones/${licitacion}/documentos`,
      values
    );
    if (response && response.data.code === 0) {
      onSave();
      onCancel();
    }
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={700}
      className="documentos-form"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}
      keyboard={false}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="form">
          <div className="title">Agregar Documentos</div>

          <Form.Item label="Documentos" name="ids" rules={[required]}>
            <Select
              mode="multiple"
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount="responsive"
            >
              {documentos.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Obligatorio" name="obligatorio" rules={[required]}>
            <Select allowClear>
              <Select.Option value={true}>Si</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>

          <div className="form-actions">
            <Button type="secondary" onClick={onCancel}>
              Volver
            </Button>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default DocumentosForm;
