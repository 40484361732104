/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import http from "../../../services/http";

import SolicitudesItem from "./SolicitudesItem";

import "./Solicitudes.scss";

const Solicitudes = (props) => {
  const { licitacion } = props;

  const [solicitudes, setSolicitudes] = useState([]);

  useEffect(() => {
    async function getList() {
      const url = `licitaciones/${licitacion}/solicitudes`;
      const response = await http.get(url);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setSolicitudes(data.solicitudes);
      }
    }
    getList();
  }, []);

  return (
    <div className="solicitudes card">
      <div className="section-header">
        <div className="section-title">Oferentes</div>
        <div className="section-subtitle">
          Proveedores que aplicaron a la contratación.
        </div>
      </div>
      <div className="solicitudes-list">
        {solicitudes.map((solicitud, index) => (
          <SolicitudesItem
            key={index}
            licitacion={licitacion}
            solicitud={solicitud}
          />
        ))}
      </div>
    </div>
  );
};

export default Solicitudes;
