import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";

import { Context } from "./../../components/Context";
import http from "./../../services/http";
import { setData } from "./../../services/security";
import { required } from "./../../services/validation";

import logo from "./../../assets/images/logo-horizontal.png";

const LoginForm = ({ setVisible }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const onReset = () => {
    setState({ ...state, current: "reset" });
  };

  const onSave = async (values) => {
    setLoading(true);

    const rSave = await http.post("login", values);
    if (rSave && rSave.data.code === 0) {
      const data = rSave.data.data;

      if (data.user.reset) {
        setState({ ...state, email: values.email, current: "update" });
      } else {
        setData(data);
        setVisible(false);
        history.push("/");
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={0} lg={8}>
        <div className="image">
          <div className="overlay">
            <div>
              <div className="header">Login</div>
              <div className="title">¡Bienvenido!</div>
            </div>
            <div className="description">Ingreso solo para administradores</div>
          </div>
        </div>
      </Col>
      <Col xs={24} lg={16}>
        <div className="form">
          <Form form={form} layout="vertical" onFinish={onSave}>
            <img className="logo" src={logo} alt="logo" />

            <Form.Item label="Email" name="email" rules={[required]}>
              <Input autoFocus={true} />
            </Form.Item>

            <Form.Item label="Contraseña" name="password" rules={[required]}>
              <Input.Password />
            </Form.Item>
            <div className="reset" onClick={onReset}>
              Olvide mi contraseña
            </div>

            <div className="form-actions">
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {!loading && "Ingresar"}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default LoginForm;
