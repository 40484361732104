/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Menu, Modal, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiFileText, FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";

import { ContextProvider } from "./../../../components/Context";
import { showSuccess } from "./../../../services/notification";
import http from "./../../../services/http";
import { actions, hasAction } from "./../../../services/security";

import Header from "./../../../components/Header";
import Table from "./../../../components/Table";
import FiltersButton from "./../../../components/FiltersButton";
import Filters from "./../../../components/Filters";

const Documentos = () => {
  const history = useHistory();

  const [state, setState] = useState({
    filters: {
      visible: false,
      nombre: "",
      tipo: "",
    },
    columns: [
      {
        title: "Nombre",
        render: (item) => item.nombre,
      },
      {
        title: "Tipo",
        render: (item) => item.tipo,
      },
    ],
    loading: true,
    dataLoaded: false,
    filtersLoaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [tipos, setTipos] = useState([]);

  const VER = hasAction(actions.DOCUMENTOS_VER);
  const CREAR = hasAction(actions.DOCUMENTOS_CREAR);
  const EDITAR = hasAction(actions.DOCUMENTOS_EDITAR);
  const ELIMINAR = hasAction(actions.DOCUMENTOS_ELIMINAR);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Documentos", url: "/configuracion/documentos" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="secondary" icon={<FiPlus />} onClick={onAdd}>
            Crear
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
        <Form.Item label="Tipo" name="tipo">
          <Select allowClear>
            {tipos.map((g) => (
              <Select.Option key={g.value} value={g.value}>
                {g.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const renderMenu = (item) => {
    return (
      <Menu className="table-actions">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("documentos/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setTipos(data.tipos);
        setState({ ...state, dataLoaded: true });
      }
    }

    getData();
  }, []);

  const onAdd = () => {
    history.push("/configuracion/documentos/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/configuracion/documentos/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este documento?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`documentos/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El documento fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="documentos card card-page">
        <Header
          title="Documentos"
          breadcrumb={breadcrumb}
          icon={<FiFileText />}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="documentos/list" renderMenu={renderMenu} />
      </div>
    </ContextProvider>
  );
};

export default Documentos;
