import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { FiChevronsRight, FiHome } from "react-icons/fi";

import "./Header.scss";

const Header = ({ icon, title, breadcrumb, buttons }) => {
  return (
    <div className="header">
      <div className="icon">{icon}</div>
      <div className="content">
        {breadcrumb && (
          <div className="breadcrumb">
            <Breadcrumb separator={<FiChevronsRight />}>
              <Breadcrumb.Item>
                <Link to="/">
                  <FiHome />
                </Link>
              </Breadcrumb.Item>
              {breadcrumb.map((item) => (
                <Breadcrumb.Item key={item.id}>
                  {item.url !== undefined ? (
                    <Link to={item.url}>{item.title}</Link>
                  ) : (
                    <span>{item.title}</span>
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
        )}
        <div className="title">{title}</div>
      </div>
      <div className="buttons">{buttons && buttons()}</div>
    </div>
  );
};

export default Header;
