import React from "react";
import { FiAlertTriangle } from "react-icons/fi";

import classes from "./Alert.module.scss";

const Alert = (props) => {
  const { type, children } = props;

  const renderIcon = () => {
    switch (type) {
      case "warning":
        return <FiAlertTriangle />;

      default:
        break;
    }
  };

  const renderClasses = () => {
    let nClasses = `${classes.alert}`;
    switch (type) {
      case "warning":
        nClasses += ` ${classes.warning}`;
        break;

      default:
        break;
    }

    return nClasses;
  };

  const renderTitle = () => {
    switch (type) {
      case "warning":
        return "Advertencia";

      default:
        break;
    }
  };

  return (
    <div className={renderClasses()}>
      <div className={classes.icon}>{renderIcon()}</div>
      <div className={classes.content}>
        <div className={classes.title}>{renderTitle()}</div>
        <div className={classes.message}>{children}</div>
      </div>
    </div>
  );
};

export default Alert;
