/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { FiPaperclip } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";

import { compressImage, convertToBase64 } from "../../services/files";
import { parseDate } from "../../services/forms";
import http from "../../services/http";
import { showWarning } from "../../services/notification";
import { required } from "../../services/validation";

import classes from "./SancionesModal.module.scss";

const SancionesModal = (props) => {
  const [form] = Form.useForm();
  const fileRef = useRef(null);

  const { proveedorId, visible, onSave, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [tipo, setTipo] = useState();
  const [archivos, setArchivos] = useState([]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setTipo();
      setArchivos([]);
    }
  }, [visible]);

  const onChangeTipo = (value) => {
    setTipo(value);
  };

  const onClickAttachment = () => {
    fileRef.current.click();
  };

  const onChangeFiles = async (e) => {
    const files = e.target.files;
    let nFiles = [];

    for (let i = 0; i < files.length; i++) {
      let nFile = files[i];

      if (nFile.type.includes("image")) {
        nFile = await compressImage(nFile);
      }

      const base64 = await convertToBase64(nFile);
      nFiles.push({
        nombre: nFile.name,
        tamanio: nFile.size,
        contenido: base64,
      });
    }

    setArchivos(nFiles);
    e.target.value = "";
  };

  const onClickSubmit = async (values) => {
    if (tipo !== "3") {
      await onSubmit(values);
      return;
    }

    Modal.confirm({
      title: "Confirmar baja",
      icon: <ExclamationCircleOutlined />,
      content:
        "Al registrar esta sanción, se va a dar de baja automaticamente a este proveedor ¿Esta seguro que desea continuar?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        await onSubmit(values);
      },
    });
  };

  const onSubmit = async (values) => {
    if (archivos.length === 0) {
      showWarning("Debe adjuntar al menos un archivo.");
      return;
    }

    values.fecha = parseDate(values.fecha);
    values.desde = parseDate(values.desde);
    values.hasta = parseDate(values.hasta);
    values.archivos = archivos;

    setLoading(true);
    const response = await http.post(
      `proveedores/${proveedorId}/sanciones`,
      values
    );
    setLoading(false);

    if (response && response.data.code === 0) {
      onSave();
      onClose();

      if (values.tipo === "3") {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };

  return (
    <Modal
      title="Registrar Sanción"
      centered
      destroyOnClose={true}
      footer={null}
      width={900}
      visible={visible}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical" onFinish={onClickSubmit}>
        <Row gutter={[24]}>
          <Col xs={12}>
            <Form.Item label="Tipo" name="tipo" rules={[required]}>
              <Select onChange={onChangeTipo}>
                <Select.Option value="1">Apercibimiento</Select.Option>
                <Select.Option value="2">Suspensión</Select.Option>
                <Select.Option value="3">Baja</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Fecha" name="fecha" rules={[required]}>
              <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
            </Form.Item>
          </Col>

          {tipo === "2" && (
            <Col xs={12}>
              <Form.Item
                label="Desde"
                name="desde"
                rules={tipo === "2" ? [required] : []}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder=""
                  locale={locale}
                />
              </Form.Item>
            </Col>
          )}
          {tipo === "2" && (
            <Col xs={12}>
              <Form.Item
                label="Hasta"
                name="hasta"
                rules={tipo === "2" ? [required] : []}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder=""
                  locale={locale}
                />
              </Form.Item>
            </Col>
          )}

          <Col xs={24}>
            <Form.Item label="Comentario" name="comentario" rules={[required]}>
              <Input.TextArea maxLength={500} showCount rows={6} />
            </Form.Item>
          </Col>
        </Row>

        <div className={classes.attachment} onClick={onClickAttachment}>
          <div className={classes.icon}>
            <FiPaperclip />
          </div>
          <div className={classes.text}>
            {archivos.length === 0
              ? "Adjuntar archivos"
              : `${archivos.length} archivos adjuntos`}
          </div>
          <input
            type="file"
            multiple={true}
            ref={fileRef}
            onChange={onChangeFiles}
          />
        </div>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Guardar"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SancionesModal;
