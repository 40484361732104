import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Modal, Select } from "antd";
import { FiFolder } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notification";
import { actions, hasAction } from "./../../../services/security";
import { required } from "./../../../services/validation";

import Header from "./../../../components/Header";
import Archivos from "./Archivos";
import Documentos from "./Documentos";

const { TextArea } = Input;

const TramitesForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [id, setId] = useState(
    match.params.id === "nuevo" ? 0 : parseInt(match.params.id)
  );
  const [title] = useState(id === 0 ? "Crear" : "Editar");
  const [loading, setLoading] = useState(false);

  const [tipos, setTipos] = useState([]);

  const VER = hasAction(actions.TRAMITES_VER);
  const CREAR = hasAction(actions.TRAMITES_CREAR);
  const EDITAR = hasAction(actions.TRAMITES_EDITAR);
  const GUARDAR = (id === 0 && CREAR) || (id > 0 && EDITAR);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Trámites", url: "/configuracion/tramites" },
    {
      id: 3,
      title: title,
      url: `/configuracion/tramites/${match.params.id}`,
    },
  ];

  useEffect(() => {
    async function getForm() {
      let response = await http.get("tramites/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setTipos(data.tipos);
      }

      response = await http.get(`tramites/${id}`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        form.setFieldsValue(data);
      }
    }

    getForm();
  }, [form, id]);

  const onSave = async (values) => {
    setLoading(true);

    const response =
      id === 0
        ? await http.post("tramites", values)
        : await http.put(`tramites/${id}`, values);

    setLoading(false);

    if (response && response.data.code === 0) {
      showSuccess("El trámite fue guardado exitosamente.");

      if (id > 0) {
        history.goBack();
        return;
      }

      const data = response.data.data;

      Modal.confirm({
        title: "Continuar editando",
        icon: <ExclamationCircleOutlined />,
        content: "¿Desea seguir editando este tramite?",
        okText: "Confirmar",
        cancelText: "Cancelar",
        onOk: () => {
          setId(data.id);
        },
        onCancel: () => history.push("/configuracion/tramites"),
      });
    }
  };

  if (!VER) {
    return <Redirect to="" />;
  }

  return (
    <div className="tramites-form ">
      <div className="card card-page">
        <Header
          title={`${title} Trámite`}
          breadcrumb={breadcrumb}
          icon={<FiFolder />}
        />

        <Form
          form={form}
          layout="vertical"
          className="form-page"
          onFinish={onSave}
        >
          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={100} />
          </Form.Item>

          <Form.Item label="Tipo" name="tipo" rules={[required]}>
            <Select allowClear>
              {tipos.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Descripción" name="descripcion">
            <TextArea maxLength={500} showCount rows={4} />
          </Form.Item>

          <div className="form-actions">
            <Button type="secondary" onClick={history.goBack}>
              Volver
            </Button>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={loading}>
                {!loading && "Guardar"}
              </Button>
            )}
          </div>
        </Form>
      </div>

      {id > 0 && <Documentos id={id} />}
      {id > 0 && <Archivos id={id} />}
    </div>
  );
};

export default TramitesForm;
