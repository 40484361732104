export const actions = {
  LICITACIONES_VER: 100,
  LICITACIONES_CREAR: 101,
  LICITACIONES_EDITAR: 102,
  LICITACIONES_ELIMINAR: 103,

  PROVEEDORES_VER: 200,
  PROVEEDORES_CREAR: 201,
  PROVEEDORES_EDITAR: 202,
  PROVEEDORES_ELIMINAR: 203,
  PROVEEDORES_PENALIDADES_VER: 204,
  PROVEEDORES_PENALIDADES_CREAR: 205,
  PROVEEDORES_PENALIDADES_EDITAR: 206,
  PROVEEDORES_PENALIDADES_ELIMINAR: 207,
  PROVEEDORES_SANCIONES_VER: 208,
  PROVEEDORES_SANCIONES_CREAR: 209,
  PROVEEDORES_SANCIONES_EDITAR: 210,
  PROVEEDORES_SANCIONES_ELIMINAR: 211,

  TRAMITES_VER: 400,
  TRAMITES_CREAR: 401,
  TRAMITES_EDITAR: 402,
  TRAMITES_ELIMINAR: 403,

  DOCUMENTOS_VER: 500,
  DOCUMENTOS_CREAR: 501,
  DOCUMENTOS_EDITAR: 502,
  DOCUMENTOS_ELIMINAR: 503,

  PERFILES_VER: 600,
  PERFILES_CREAR: 601,
  PERFILES_EDITAR: 602,
  PERFILES_ELIMINAR: 603,

  USUARIOS_VER: 700,
  USUARIOS_CREAR: 701,
  USUARIOS_EDITAR: 702,
  USUARIOS_ELIMINAR: 703,

  FERIADOS_VER: 800,
  FERIADOS_CREAR: 801,
  FERIADOS_EDITAR: 802,
  FERIADOS_ELIMINAR: 803,
};

export const setData = (data) => {
  localStorage.setItem("token", data.token);
  localStorage.setItem("user", JSON.stringify(data.user));
};

export const setLocalStorage = (object, value) => {
  localStorage.setItem(object, value);
};

export const getLocalStorage = (object) => {
  return localStorage.getItem(object);
};

export const getUser = () => {
  const user = localStorage.getItem("user");
  return user === null ? { nombre: "" } : JSON.parse(user);
};

export const getJwt = () => {
  return localStorage.getItem("token");
};

export const hasAction = (action) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const permisos = JSON.parse(atob(user.permisos));
    return permisos.indexOf(action) > -1;
  } catch (error) {
    return false;
  }
};
