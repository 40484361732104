/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Modal, Select, Row, Col } from "antd";
import InputMask from "react-input-mask";
import { FiBriefcase } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import http from "./../../services/http";
import { showSuccess, showWarning } from "./../../services/notification";
import { actions, hasAction } from "./../../services/security";
import { required } from "./../../services/validation";

import Alert from "../../components/Alert";
import Header from "./../../components/Header";
import Documentos from "./Documentos";
import RegistroFirmas from "./RegistroFirmas";
import Penalidades from "./Penalidades";
import Sanciones from "./Sanciones";

import "./Proveedores.scss";
import Socios from "./Socios";

const { Option } = Select;

const ProveedoresForm = ({ match, location }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [id] = useState(
    match.params.id === "nuevo" ? 0 : parseInt(match.params.id)
  );
  const [title] = useState(id === 0 ? "Crear" : "Editar");
  const [loading, setLoading] = useState(false);

  const [readonly, setReadonly] = useState(
    id !== 0 && new URLSearchParams(location.search).get("edit") !== "true"
  );
  const [editor, setEditor] = useState();

  const [actividades, setActividades] = useState([]);
  const [sexos, setSexos] = useState([]);
  const [tipos, setTipos] = useState([]);

  const [documentacion, setDocumentacion] = useState([]);
  const [documentacionReload, setDocumentacionReload] = useState(false);

  const [sexoRequired, setSexoRequired] = useState(false);
  const [representanteRequired, setRepresentanteRequired] = useState(false);
  const [motivoRequired, setMotivoRequired] = useState(false);

  const [estado, setEstado] = useState();
  const [actualizarVisible, setActualizarVisible] = useState(false);
  const [registroFirmas, setRegistroFirmas] = useState(false);
  const [sociosVisible, setSociosVisible] = useState(false);
  const [socios, setSocios] = useState([]);

  const VER = hasAction(actions.PROVEEDORES_VER);
  const CREAR = hasAction(actions.PROVEEDORES_CREAR);
  const EDITAR = hasAction(actions.PROVEEDORES_EDITAR);
  const GUARDAR = (id === 0 && CREAR) || (id > 0 && EDITAR);

  const breadcrumb = [
    { id: 1, title: "Proveedores", url: "/proveedores" },
    {
      id: 3,
      title: title,
      url: `/proveedores/${match.params.id}`,
    },
  ];

  useEffect(() => {
    const clearUser = async () => {
      if (readonly) {
        return;
      }

      await http.patch(`proveedores/${id}/editor`);
    };

    const handleTabClose = async (event) => {
      event.preventDefault();
      await clearUser();
      return true;
    };

    window.addEventListener("beforeunload", handleTabClose);

    return async () => {
      await clearUser();
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    async function getForm() {
      let response = await http.get("proveedores/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setActividades(data.actividades);
        setSexos(data.sexos);
        setTipos(data.tipos);
      }

      response = await http.get(`proveedores/${id}?edit=${!readonly}`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        data.tipo = data.tipo && data.tipo.toString();
        data.sexo = data.sexo && data.sexo.toString();
        setReadonly(data.editor !== undefined || readonly);
        setEditor(data.editor);
        setEstado(data.estado);
        setSocios(data.socios);

        if (
          data.estado !== "3" &&
          data.estado !== "4" &&
          data.estado !== "5" &&
          data.estado !== "6" &&
          data.estado !== "7"
        ) {
          data.estado = undefined;
        }

        setRegistroFirmas(data.documentacionFirmada);
        onChangeTipo(data.tipo);
        onChangeEstado(data.estado, true);
        form.setFieldsValue(data);
      }
    }

    getForm();
  }, []);

  useEffect(() => {
    async function getDocumentacion() {
      const response = await http.get(`proveedores/archivos/${id}`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDocumentacion(data.documentos);
      }
    }

    getDocumentacion();
  }, [documentacionReload]);

  const onReloadDocumentacion = () => {
    setDocumentacionReload(!documentacionReload);
  };

  const onChangeTipo = (value) => {
    setSociosVisible(value === "3");

    if (!value) {
      return;
    }

    if (value === "1") {
      form.setFieldsValue({ representanteLegal: null });
      setRepresentanteRequired(false);
      setSexoRequired(true);
      return;
    }

    form.setFieldsValue({ sexo: null });
    setRepresentanteRequired(true);
    setSexoRequired(false);
  };

  const onChangeEstado = (value, ignore = false) => {
    if (ignore) {
      return;
    }

    if (value === "4") {
      setActualizarVisible(true);
    }

    if (value === "3") {
      setMotivoRequired(true);
    } else {
      setMotivoRequired(false);
    }
  };

  const onChangeRegistroFirmas = async () => {
    if (estado === "4") {
      return;
    }

    const url = `proveedores/${id}/registro-firmas`;
    await http.patch(url, {});
    setRegistroFirmas(!registroFirmas);
  };

  const onSave = async (values) => {
    if (id > 0 && values.estado !== estado && values.estado === "6") {
      Modal.confirm({
        title: "Confirmar baja",
        icon: <ExclamationCircleOutlined />,
        content: "¿Esta seguro que desea dar de baja a este proveedor?",
        okText: "Confirmar",
        cancelText: "Cancelar",
        onOk: async () => {
          await submitForm(values);
          return;
        },
      });

      return;
    }

    await submitForm(values);
  };

  const submitForm = async (values) => {
    values.socios = socios;

    if (values.tipo === "3" && values.socios.length === 0) {
      showWarning("Debe cargar los datos de al menos un socio para continuar.");
      return;
    }

    setLoading(true);
    const response =
      id === 0
        ? await http.post("proveedores", values)
        : await http.put(`proveedores/${id}`, values);
    setLoading(false);

    if (response && response.data.code === 0) {
      history.goBack();
      showSuccess("El proveedor fue guardado exitosamente.");
    }
  };

  if (!VER) {
    return <Redirect to="" />;
  }

  return (
    <div className="proveedores-form">
      <div className="card card-page">
        <Header
          title={`${title} Proveedor`}
          breadcrumb={breadcrumb}
          icon={<FiBriefcase />}
        />

        <Form
          form={form}
          layout="vertical"
          className="form-page"
          onFinish={onSave}
        >
          {editor && (
            <Alert type="warning">
              Este proveedor esta siendo editado por <strong>{editor}</strong>.
            </Alert>
          )}

          <Row gutter={[24]}>
            <Col xs={24} lg={24}>
              <Form.Item label="Número de Inscripción" name="numeroInscripcion">
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col xs={24} lg={6}>
              <Form.Item label="Tipo de Persona" name="tipo" rules={[required]}>
                <Select onChange={onChangeTipo} disabled={true}>
                  {tipos.map((g) => (
                    <Option key={g.value} value={g.value}>
                      {g.text.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={10}>
              <Form.Item
                label="Nombre / Razón Social"
                name="nombre"
                rules={[required]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            {sexoRequired && (
              <Col xs={24} lg={4}>
                <Form.Item label="Sexo" name="sexo" rules={[required]}>
                  <Select disabled={true}>
                    {sexos.map((g) => (
                      <Option key={g.value} value={g.value}>
                        {g.text.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xs={24} lg={sexoRequired ? 4 : 8}>
              <Form.Item label="CUIT" name="cuil" rules={[required]}>
                <InputMask
                  mask="99 99999999 9"
                  maskChar=" "
                  disabled={true}
                >
                  {(inputProps) => <Input {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>


            <Col xs={24} lg={12}>
              <Form.Item
                label="Domicilio especial para notificar dentro del radio del gran Mendoza"
                name="direccionEspecial"
                rules={[required]}
              >

                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Número" name="numeroEspecial" rules={[required]}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Piso" name="pisoEspecial">
                <Input disabled={true} />

              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Departamento" name="departamentoEspecial">
                <Input disabled={true} />
              </Form.Item>
            </Col>


            <Col xs={24} lg={12}>
              <Form.Item
                label="Domicilio real"
                name="direccion"
                rules={[required]}
              >

                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Número" name="numero" rules={[required]}>

                <Input disabled={true} />

              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Piso" name="piso">


                <Input disabled={true} />

              </Form.Item>
            </Col>
            <Col xs={8} lg={4}>
              <Form.Item label="Departamento" name="departamento">

                <Input disabled={true} />

              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label="Provincia"
                name="provincia"
                rules={id === 0 ? [] : [required]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="Localidad"
                name="localidad"
                rules={id === 0 ? [] : [required]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item label="Email" name="email" rules={[required]}>
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={6}>
              <Form.Item label="Teléfono Fijo" name="telefono" rules={[required]}>
                <Input disabled={true} />

              </Form.Item>
            </Col>

            <Col xs={24} lg={6}>
              <Form.Item label="Teléfono Celular" name="celular" rules={[required]}>
                <Input disabled={true} />
              </Form.Item>
            </Col>




            <Col xs={24} lg={24}>
              <Form.Item
                label="Actividades Principales"
                name="actividadesPrimarias"
                rules={[required]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  maxTagCount="responsive"
                  disabled={true}
                >
                  {actividades.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item
                label="Actividades Secundarias"
                name="actividadesSecundarias"
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  maxTagCount="responsive"
                  disabled={true}
                >
                  {actividades.map((x) => (
                    <Option key={x.value} value={x.value}>
                      {x.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {representanteRequired && (
              <Col xs={24}>
                <Form.Item
                  label="Representante Legal"
                  name="representanteLegal"
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            )}

            {sociosVisible && (
              <Socios
                list={socios}
                updateList={setSocios}
                readonly={readonly}
              />
            )}




            {id > 0 && (
              <Row gutter={[24]}>
                <Col xs={24} lg={24}>
                  <Documentos
                    readonly={readonly}
                    update
                    documentos={documentacion}
                    reload={onReloadDocumentacion}
                    registroFirmas={registroFirmas}
                    onChangeRegistroFirmas={onChangeRegistroFirmas}
                  />
                </Col>

                <Col xs={24} lg={24}>
                  <RegistroFirmas
                    readonly={readonly}
                    update
                    checked={registroFirmas}
                    onChange={onChangeRegistroFirmas}
                    documentos={documentacion}
                    reload={onReloadDocumentacion}
                  />
                </Col>

                <Col xs={24} lg={24}>
                  <Penalidades readonly={readonly} proveedorId={id} />
                </Col>

                <Col xs={24} lg={24}>
                  <Sanciones readonly={readonly} proveedorId={id} />
                </Col>
              </Row>
            )}


            {id > 0 && (
              <Col xs={24}>
                <Form.Item label="Estado" name="estado" rules={[required]}>
                  <Select
                    disabled={readonly}
                    allowClear
                    onChange={(value) => onChangeEstado(value, false)}
                  >
                    
                    <Option
                      value="4"
                      disabled={
                        documentacion.some((x) =>
                          x.archivos.some(
                            (y) => y.estado === 2 || y.estado === 1
                          )
                        ) ||
                        (estado === "5" && !registroFirmas)
                      }
                    >
                      Aprobado
                    </Option>
                    <Option
                      value="3"
                      disabled={documentacion.some((x) =>
                        x.archivos.some((y) => y.estado === 1)
                      )}
                    >
                      Observado
                    </Option>
                    <Option value="7" disabled>
                      Suspendido
                    </Option>
                    <Option value="6" disabled>
                      Dado de Baja
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            {actualizarVisible && (
              <Col xs={24} lg={24}>
                <Form.Item
                  label="Renovar fecha de actualización"
                  name="actualizar"
                  rules={[required]}
                >
                  <Select>
                    <Option value="1">Si</Option>
                    <Option value="0">No</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            {motivoRequired && (
              <Col xs={24} lg={24}>
                <Form.Item label="Motivo" name="motivo" rules={[required]}>
                  <Input.TextArea rows={6} />
                </Form.Item>
              </Col>
            )}
          </Row>
          <div className="form-actions">
            <Button type="secondary" onClick={history.goBack}>
              Volver
            </Button>
            {GUARDAR && (id === 0 || !readonly) && (
              <Button type="primary" htmlType="submit" loading={loading}>
                {!loading && "Guardar"}
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ProveedoresForm;
