/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Menu, Modal } from "antd";
import { FiCheck, FiDownload, FiFolderMinus } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ContextProvider } from "../../../components/Context";
import { showSuccess } from "../../../services/notification";
import http from "../../../services/http";
import { actions, hasAction } from "../../../services/security";

import Header from "../../../components/Header";
import Table from "../../../components/Table";
import FiltersButton from "../../../components/FiltersButton";
import Filters from "../../../components/Filters";

const SolicitudesBaja = () => {
  const [state, setState] = useState({
    filters: {
      visible: false,
      nombre: "",
    },
    columns: [
      {
        title: "#",
        style: { textAlign: "center" },
        render: (item) => item.numeroInscripcion,
      },
      {
        title: "Nombre / Razón Social",
        render: (item) => item.proveedor,
      },
      {
        title: "Fecha",
        render: (item) => item.fecha,
      },
    ],
    loading: true,
    dataLoaded: true,
    filtersLoaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const VER = hasAction(actions.PROVEEDORES_VER);
  const EDITAR = hasAction(actions.PROVEEDORES_EDITAR);

  const breadcrumb = [
    {
      id: 1,
      title: "Proveedores",
    },
    {
      id: 2,
      title: "Solicitudes de Baja",
      url: "/proveedores/solicitudes-baja",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Nombre / Razón Social" name="nombre">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  const renderMenu = (item) => {
    return (
      <Menu className="table-actions">
        {VER && (
          <Menu.Item key="0" onClick={() => onClickDownload(item)}>
            <FiDownload />
            Descargar
          </Menu.Item>
        )}
        {EDITAR && (
          <Menu.Item key="1" onClick={() => onClickApprove(item)}>
            <FiCheck />
            Aprobar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onClickDownload = (item) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${item.archivoId}`,
      "_blank"
    );
  };

  const onClickApprove = (item) => {
    Modal.confirm({
      title: "Confirmar aprobación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea aprobar la solicitud de baja?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const response = await http.patch(`proveedores/baja/${item.id}`);
        if (response && response.data.code === 0) {
          showSuccess("El proveedor fue dado de baja exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="proveedores card card-page">
        <Header
          title="Solicitudes de Baja"
          breadcrumb={breadcrumb}
          icon={<FiFolderMinus />}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="proveedores/baja/list" renderMenu={renderMenu} />
      </div>
    </ContextProvider>
  );
};

export default SolicitudesBaja;
