/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { Badge } from "antd";
import {
  FiCalendar,
  FiHome,
  FiFileText,
  FiDollarSign,
  FiFolder,
  FiFolderMinus,
  FiFolderPlus,
  FiBriefcase,
  FiUser,
  FiShield,
  FiX,
} from "react-icons/fi";

import http from "../services/http";
import { actions, hasAction } from "./../services/security";

import logo from "../assets/images/logo.png";

import "./SideBar.scss";

const SideBar = ({ collapsed, onCollapse }) => {
  const [menu, setMenu] = useState([
    {
      visible: true,
      items: [
        {
          icon: <FiHome />,
          title: "Inicio",
          url: "/",
          exact: true,
          visible: true,
        },
        {
          icon: <FiDollarSign />,
          title: "Contrataciones",
          url: "/contrataciones",
          exact: false,
          visible: hasAction(actions.LICITACIONES_VER),
        },
      ],
    },
    {
      title: "Proveedores",
      visible: hasAction(actions.PROVEEDORES_VER),
      items: [
        {
          icon: <FiBriefcase />,
          title: "Proveedores",
          url: "/proveedores",
          exact: true,
          visible: hasAction(actions.PROVEEDORES_VER),
        },
        {
          icon: <FiFolderPlus />,
          title: "Solicitudes de Alta",
          url: "/proveedores/solicitudes-alta",
          exact: false,
          visible: hasAction(actions.PROVEEDORES_VER),
        },
        {
          icon: <FiFolderMinus />,
          title: "Solicitudes de Baja",
          url: "/proveedores/solicitudes-baja",
          exact: false,
          visible: hasAction(actions.PROVEEDORES_VER),
        },
      ],
    },
    {
      title: "Configuración",
      visible:
        hasAction(actions.TRAMITES_VER) ||
        hasAction(actions.DOCUMENTOS_VER) ||
        hasAction(actions.FERIADOS_VER),
      items: [
        {
          icon: <FiFolder />,
          title: "Trámites",
          url: "/configuracion/tramites",
          exact: false,
          visible: hasAction(actions.TRAMITES_VER),
        },
        {
          icon: <FiFileText />,
          title: "Documentos",
          url: "/configuracion/documentos",
          exact: false,
          visible: hasAction(actions.DOCUMENTOS_VER),
        },
        {
          icon: <FiCalendar />,
          title: "Feriados",
          url: "/configuracion/feriados",
          exact: false,
          visible: hasAction(actions.FERIADOS_VER),
        },
      ],
    },
    {
      title: "Seguridad",
      visible:
        hasAction(actions.PERFILES_VER) || hasAction(actions.USUARIOS_VER),
      items: [
        {
          icon: <FiShield />,
          title: "Perfiles",
          url: "/seguridad/perfiles",
          exact: false,
          visible: hasAction(actions.PERFILES_VER),
        },
        {
          icon: <FiUser />,
          title: "Usuarios",
          url: "/seguridad/usuarios",
          exact: false,
          visible: hasAction(actions.USUARIOS_VER),
        },
      ],
    },
  ]);

  useEffect(() => {
    async function getData() {
      let nMenu = [...menu];

      let response = await http.get("proveedores/alta/pendientes");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        nMenu[1].items[1].badge = data.total > 0 ? data.total : undefined;
      }

      response = await http.get("proveedores/baja/pendientes");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        nMenu[1].items[2].badge = data.total > 0 ? data.total : undefined;
      }

      setMenu(nMenu);
    }

    getData();
  }, []);

  const getSize = () => {
    return collapsed
      ? { width: "80px", height: "calc(100% - 86px)" }
      : { width: "260px", height: "calc(100% - 86px)" };
  };

  const getOverlay = () => {
    return collapsed ? "sidebar-overlay" : "sidebar-overlay visible";
  };

  const getClasses = () => {
    return collapsed ? "sidebar collapsed" : "sidebar";
  };

  return (
    <div className={getOverlay()}>
      <div className={getClasses()}>
        <div className="sidebar-header">
          <img className="sidebar-logo" src={logo} alt="logo" />
          <p className="sidebar-title">
            <span className="small">Compras</span>
            <span className="name">Municipalidad</span>
          </p>
          <FiX className="sidebar-collapse" onClick={() => onCollapse(true)} />
        </div>
        <Scrollbars className="sidebar-scroll" style={getSize()} autoHide>
          <ul className="sidebar-list">
            {menu.map(
              (section, index) =>
                section.visible && (
                  <React.Fragment key={index}>
                    {section.title && (
                      <li className="nav-header">{section.title}</li>
                    )}
                    {section.items.map(
                      (item, index2) =>
                        item.visible && (
                          <li key={index2} className="nav-item">
                            <NavLink to={item.url} exact={item.exact}>
                              {item.icon}
                              <div>{item.title}</div>
                              {item.badge && <Badge count={item.badge} />}
                            </NavLink>
                          </li>
                        )
                    )}
                  </React.Fragment>
                )
            )}
          </ul>
        </Scrollbars>
      </div>
    </div>
  );
};

export default SideBar;
