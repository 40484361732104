/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Form, Input, Menu, Modal, Popover, Select, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiFolderPlus, FiEdit, FiSearch, FiTrash2 } from "react-icons/fi";

import { ContextProvider } from "../../../components/Context";
import { showSuccess } from "../../../services/notification";
import http from "../../../services/http";
import { actions, hasAction } from "../../../services/security";

import Header from "../../../components/Header";
import Table from "../../../components/Table";
import FiltersButton from "../../../components/FiltersButton";
import Filters from "../../../components/Filters";

const SolicitudesAlta = () => {
  const history = useHistory();

  const [state, setState] = useState({
    filters: {
      visible: false,
      nombre: "",
      cuil: "",
      tipo: null,
    },
    columns: [
      {
        title: "#",
        style: { textAlign: "center" },
        render: (item) => item.numeroInscripcion,
      },
      {
        title: "Nombre / Razón Social",
        render: (item) => item.nombre,
      },
      {
        title: "CUIT",
        render: (item) => item.cuil,
      },
      {
        title: "Estado",
        render: (item) => item.estado,
      },
      {
        title: "Últ. Actualización",
        render: (item) => item.actualizacion,
      },
      {
        title: "Motivo",
        render: (item) => {
          if (!item.motivo) {
            return;
          }

          if (item.motivo.length > 50) {
            const content = <div style={{ width: "300px" }}>{item.motivo}</div>;

            return (
              <Popover content={content} trigger="hover">
                {item.motivo.substring(0, 50)}...
              </Popover>
            );
          }

          return item.motivo;
        },
      },
      {
        render: (item) =>
          item.editor && (
            <Tooltip
              placement="top"
              title={`Este proveedor esta siendo editado por ${item.editor}`}
            >
              <div className="table-warning">
                <FiEdit />
              </div>
            </Tooltip>
          ),
      },
    ],
    loading: true,
    dataLoaded: false,
    filtersLoaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [tipos, setTipos] = useState([]);

  const VER = hasAction(actions.PROVEEDORES_VER);
  const EDITAR = hasAction(actions.PROVEEDORES_EDITAR);
  const ELIMINAR = hasAction(actions.PROVEEDORES_ELIMINAR);

  const breadcrumb = [
    {
      id: 1,
      title: "Proveedores",
    },
    {
      id: 2,
      title: "Solicitudes de Alta",
      url: "/proveedores/solicitudes-alta",
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Nombre / Razón Social" name="nombre">
          <Input />
        </Form.Item>
        <Form.Item label="CUIT" name="cuil">
          <Input />
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select allowClear>
            <Select.Option value="2">Pendiente Habilitación</Select.Option>
            <Select.Option value="3">Observada</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Tipo" name="tipo">
          <Select allowClear>
            {tipos.map((g) => (
              <Select.Option key={g.value} value={g.value}>
                {g.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const renderMenu = (item) => {
    return (
      <Menu className="table-actions">
        {VER && (
          <Menu.Item key="0" onClick={() => onEdit(item, false)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        {EDITAR && (
          <Menu.Item key="1" onClick={() => onEdit(item, true)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="2" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      setState({ ...state, loading: true });

      const response = await http.get("proveedores/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setTipos(data.tipos);
        setState({ ...state, dataLoaded: true });
      }
    }

    getData();
  }, []);

  const onEdit = (item, edit) => {
    history.push(`/proveedores/${item.id}?edit=${edit}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este proveedor?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`proveedores/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El proveedor fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="proveedores card card-page">
        <Header
          title="Solicitudes de Alta"
          breadcrumb={breadcrumb}
          icon={<FiFolderPlus />}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="proveedores/alta/list" renderMenu={renderMenu} />
      </div>
    </ContextProvider>
  );
};

export default SolicitudesAlta;
